import Vue from 'vue';
import Router from 'vue-router';

import {useAuthService} from '@/auth/services/authService';
import {authMiddleware} from '@/router/auth';
import {
  ACT_AS_USER,
  ASSIGNMENT_OVERVIEW,
  ASSIGNMENT_SHOW,
  CHANGE_PASSWORD,
  COURSE_ASSIGNMENTS,
  COURSE_ASSIGNMENTS_COPY,
  COURSE_HOME,
  DASHBOARD,
  DRAWING_COMPONENT,
  INERTIA_LEGACY_TASK,
  INVITE_A_USER,
  LOGIN,
  NOT_FOUND,
  PROFILE,
  QUIZ,
  RESET_PASSWORD,
  SEARCH_USER,
  SUPER_ADMIN_ROOT,
  TASK_EDITOR,
  VERIFY_EMAIL,
} from '@/router/route-names';

// Authorizers
import {isSuperUser} from '@/router/authorizers';

import DummyView from '@/common/components/DummyView.vue';
import {assignmentNameRenderer, courseNameRenderer} from '@/router/breadcrumbs';

const Dashboard = () => import('@/dashboard/views/Dashboard.vue');
const DashboardIndex = () => import('@/dashboard/views/DashboardIndex');
const IndexPages = () => import('@/AppIndex');

// Faculty & Students Pages
const Assignment = () => import('@/assignments/views/Assignment.vue');
const AssignmentWrapper = () => import('@/assignments/views/AssignmentWrapper.vue');

const CourseWrapper = () => import('@/courses/views/CourseWrapper.vue');
const Quiz = () => import('@/assignments/views/Quiz.vue');
// General Pages
const Error404 = () => import('@/errors/views/NotFound');
const PasswordReset = () => import('@/auth/views/PasswordReset');
const VerifyEmail = () => import('@/auth/views/VerifyEmail');
const ActAs = () => import('@/super-admin/views/ActAs');
const InviteUser = () => import('@/super-admin/views/InviteUser');
const CourseAssignmentsCopy = () => import('@/super-admin/views/CourseAssignmentsCopy');
const SearchUser = () => import('@/super-admin/views/SearchUser');
const DrawingComponent = () => import('@/super-admin/views/DrawingComponent');

const LegacyTask = () => import('@/tasks/components/LegacyTask.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      redirect() {
        const authService = useAuthService();
        return {name: authService.isUserAnonymous ? LOGIN : DASHBOARD};
      },
    },
    {
      path: '/',
      component: DashboardIndex,
      meta: {
        requiresVerified: true,
      },
      children: [
        {
          name: DASHBOARD,
          path: '/dashboard',
          component: Dashboard,
        },

        {
          path: '/courses/:courseId',
          component: CourseWrapper,
          meta: {
            breadcrumbs: {
              name: COURSE_HOME,
              text: courseNameRenderer,
            },
          },
          children: [
            {
              path: '',
              name: COURSE_HOME,
              beforeEnter(to) {
                window.location.replace('/courses/' + to.params.courseId);
              },
            },
            {
              path: 'assignments',
              component: DummyView,
              meta: {
                breadcrumbs: {
                  name: COURSE_ASSIGNMENTS,
                  getHref: ({courseId}) => {
                    return `/courses/${courseId}/assignments`;
                  },
                },
              },
              children: [
                {
                  path: ':assignmentId',
                  component: AssignmentWrapper,
                  meta: {
                    breadcrumbs: {
                      name: ASSIGNMENT_SHOW,
                      text: assignmentNameRenderer,
                    },
                  },
                  children: [
                    {
                      name: ASSIGNMENT_SHOW,
                      path: '',
                      beforeEnter(to) {
                        window.location.replace(
                          '/courses/' +
                            to.params.courseId +
                            '/assignments/' +
                            to.params.assignmentId
                        );
                      },
                    },
                    {
                      name: ASSIGNMENT_OVERVIEW,
                      path: 'overview',
                      component: Assignment,
                      meta: {
                        breadcrumbs: {
                          name: ASSIGNMENT_OVERVIEW,
                          text: 'Edit Tasks',
                        },
                      },
                    },
                    {
                      path: 'tasks',
                      component: Quiz,
                      meta: {
                        breadcrumbs: {
                          name: QUIZ,
                          text: 'Tasks',
                        },
                      },
                      children: [
                        {
                          name: QUIZ,
                          path: ':taskNumber?',
                          beforeEnter(to) {
                            // Redirect to inertia page, if some gets here somehow
                            window.location.replace(
                              `/web/courses/${to.params.courseId}/assignments/${to.params.assignmentId}/tasks/${to.params.taskNumber}`
                            );
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: PROFILE,
          path: '/profile',
          beforeEnter() {
            // Put the full page URL including the protocol http(s) below
            window.location.replace('/profile');
          },
        },
        {
          path: '/password/edit',
          name: CHANGE_PASSWORD,
          beforeEnter() {
            // Put the full page URL including the protocol http(s) below
            window.location.replace('/password/edit');
          },
        },
        {
          name: SUPER_ADMIN_ROOT,
          path: '/admin',
          meta: {
            authorize: isSuperUser(),
          },
          component: DummyView,
          children: [
            {
              name: ACT_AS_USER,
              path: 'act-as',
              component: ActAs,
            },
            {
              name: INVITE_A_USER,
              path: 'invite-user',
              component: InviteUser,
            },
            {
              name: COURSE_ASSIGNMENTS_COPY,
              path: 'copy-course-assignments',
              component: CourseAssignmentsCopy,
            },
            {
              name: SEARCH_USER,
              path: 'user-search',
              component: SearchUser,
            },
            {
              name: DRAWING_COMPONENT,
              path: 'drawing-component',
              component: DrawingComponent,
            },
          ],
        },
        {
          path: '/tasks/:taskId',
          component: DummyView,
          meta: {
            breadcrumbs: {
              name: TASK_EDITOR,
            },
            authorize({user}) {
              return user.isSuperUser();
            },
          },
          children: [
            {
              name: TASK_EDITOR,
              path: 'metadata',
              component: () => import('@/tasks/views/TaskEditor.vue'),
            },
          ],
        },
      ],
    },

    {
      path: '/',
      component: IndexPages,
      meta: {
        requiresNotVerified: true,
      },
      children: [
        {
          name: VERIFY_EMAIL,
          path: '/verify/:token?',
          component: VerifyEmail,
        },
      ],
    },

    {
      path: '/',
      component: IndexPages,
      meta: {
        requiresAnonymous: true,
      },
      children: [
        {
          name: RESET_PASSWORD,
          path: '/password/reset/:token?',
          component: PasswordReset,
        },
      ],
    },
    {
      path: '/',
      component: IndexPages,
      children: [
        {
          name: NOT_FOUND,
          path: '/404',
          component: Error404,
        },
      ],
    },
    {
      name: INERTIA_LEGACY_TASK,
      path: '/inertia/task',
      component: LegacyTask,
    },

    {
      path: '*',
      redirect: '/404',
    },
  ],
});
router.beforeEach(authMiddleware);

export default router;
